import React, { useState } from "react"
import Link from "./utils/link"
import NavLink from "./utils/navLink"
import { Box, Container, Flex, Image, Text } from "@theme-ui/components"
import tobeabLogo from "../images/tobeab-logo.svg"
import abLogo from "../images/logo-ab.svg"
import { useCategories } from "../hooks/useCategories"
import { getCategoryPath } from "../utils/path"
import { ArrowLeft, ArrowRight, Menu, Search } from "react-feather"
import { motion, AnimatePresence } from "framer-motion"
import SwiperCore, { Navigation, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
import LanguageSelector from "./languageSelector"
import { i18nContext } from "../context/i18nContext"
import { getSearchPath } from "../utils/path"

SwiperCore.use([Mousewheel, Navigation, A11y])

const Header = ({ locale }) => {
  const categories = useCategories(locale)
  const menuCategories = categories.filter(category => category.menu)

  const [showMenu, setShowMenu] = useState(false)
  // console.log(categories)
  // console.log(menuCategories)

  return (
    <>
      <Container as="header" data-datocms-noindex>
        <Text
          as="p"
          sx={{
            fontSize: 1,
            mt: 0,
            mb: 3,
            textAlign: "left",
            color: "#aaaaaa",
          }}
        >
          <i18nContext.Consumer>{t => t.disclaimer}</i18nContext.Consumer>
        </Text>
        <Flex
          sx={{
            borderBottom: "1px solid",
            borderColor: "dark",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          pt={[3, 2]}
          pb={[3, 2]}
        >
          <Flex
            sx={{
              justifyContent: "space-between",
              justifyItems: ["center", "center", "center", "baseline"],
              alignItems: "center",
              width: "100%",
            }}
          >
            <Link
              to="/"
              sx={{
                display: "flex",
              }}
            >
              <Image
                src={tobeabLogo}
                alt="To Be AB"
                sx={{ maxWidth: ["120px", "120px", "160px", "160px"] }}
              />
            </Link>
            <Flex sx={{ alignItems: "center" }}>
              <Link
                to={getSearchPath(locale)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mr: [2, 3],
                  color: "dark",
                  textDecoration: "none",
                  gap: [2],
                  backgroundColor: "#fafafa",
                  paddingY: [2, 2],
                  paddingX: [2, 3],
                  borderRadius: "1000px",
                  border: "1px solid",
                  borderColor: "#ededed",
                  transition: "all 0.2s ease-in-out",
                }}
              >
                <Search size={24} />
                <Box
                  sx={{
                    display: ["none", "none", "flex", "flex"],
                    flexDirection: "column",
                  }}
                >
                  <i18nContext.Consumer>{t => t.search}</i18nContext.Consumer>
                </Box>
              </Link>
              <LanguageSelector />
              <Box
                sx={{
                  display: ["block", "none", "none", "none"],
                  color: "dark",
                  marginLeft: 4,
                }}
                role="button"
                tabIndex={0}
                onClick={() => setShowMenu(!showMenu)}
              >
                <Menu />
              </Box>
            </Flex>
          </Flex>
          <Image
            src={abLogo}
            alt="Gruppo AB"
            ml={4}
            sx={{
              display: ["none", "block", "block", "block"],
              maxWidth: "140px",
            }}
          />
        </Flex>
        <Box
          sx={{
            paddingX: 0,
            paddingY: [3],
            margin: 0,
            display: ["none", "flex", "flex", "flex"],
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid",
            borderColor: "dark",
          }}
        >
          <HorizontalMenu categories={menuCategories} />
        </Box>
      </Container>
      <AnimatePresence>
        {showMenu && (
          <AnimatedContainer
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.15 }}
            exit={{ opacity: 0 }}
          >
            <Box
              data-datocms-noindex
              as="ul"
              sx={{
                listStyle: "none",
                padding: 0,
                margin: 0,
              }}
            >
              {menuCategories.map(category => (
                <Box as="li" sx={{ marginRight: 4 }} key={category.id}>
                  <NavLink
                    to={`/${getCategoryPath(category.slug, category.locale)}`}
                  >
                    {category.name}
                  </NavLink>
                </Box>
              ))}
            </Box>
          </AnimatedContainer>
        )}
      </AnimatePresence>
    </>
  )
}

let AnimatedContainer = motion(Container)

const HorizontalMenu = ({ categories }) => (
  <>
    <Flex
      as="button"
      sx={{
        marginRight: "0.25rem",
        alignItems: "center",
        color: "primary",
        border: "none",
        background: "none",
        cursor: "pointer",
        "&.swiper-button-disabled": {
          opacity: 0.4,
        },
      }}
      className="prev-collection"
    >
      <ArrowLeft size={32} />
    </Flex>
    <Box sx={{ width: "100%", ".swiper-slide": { width: "auto" } }}>
      <Swiper
        spaceBetween={32}
        slidesPerView={"auto"}
        slidesPerGroup={4}
        navigation={{
          nextEl: ".next-collection",
          prevEl: ".prev-collection",
        }}
        watchOverflow
      >
        {categories.map(category => (
          <SwiperSlide key={category.id}>
            <Box sx={{ display: "inline-block" }}>
              <NavLink
                to={`/${getCategoryPath(category.slug, category.locale)}`}
              >
                {category.name}
              </NavLink>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
    <Flex
      as="button"
      sx={{
        marginLeft: "0.25rem",
        color: "primary",
        alignItems: "center",
        border: "none",
        background: "none",
        cursor: "pointer",
        "&.swiper-button-disabled": {
          opacity: 0.4,
        },
      }}
      className="next-collection"
    >
      <ArrowRight size={32} />
    </Flex>
  </>
)

export default Header
