const i18nPath = {
  it: {
    article: "articolo",
    category: "categoria",
  },
  en: {
    article: "article",
    category: "category",
  },
}

export function getArticlePath(slug, locale) {
  return locale === "it"
    ? `${i18nPath[locale].article}/${slug}/`
    : `${locale}/${i18nPath[locale].article}/${slug}/`
}

export function getSearchPath(locale) {
  return locale === "it" ? "/ricerca" : `/${locale}/search`
}

export function getPagePath(slug, locale) {
  return locale === "it" ? `/${slug}/` : `/${locale}/${slug}/`
}

export function getCategoryPath(slug, locale) {
  return locale === "it"
    ? `${i18nPath[locale].category}/${slug}/`
    : `${locale}/${i18nPath[locale].category}/${slug}/`
}
