import React from "react"
import { Box, Container, Flex, Image, Grid, Text } from "@theme-ui/components"
import { useStaticQuery, graphql } from "gatsby"
import facebookIcon from "../images/icons/facebook.svg"
import instagramIcon from "../images/icons/instagram.svg"
import linkedinIcon from "../images/icons/linkedin.svg"
import youtubeIcon from "../images/icons/youtube.svg"
import { getPagePath } from "../utils/path"
import Link from "../components/utils/link"
import { i18nContext } from "../context/i18nContext"

const Footer = ({ locale }) => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      allDatoCmsFooter {
        nodes {
          id
          locale
          legal
          links
          website
        }
      }
    }
  `)

  const i18nFooter = data.allDatoCmsFooter.nodes.filter(
    footer => footer.locale === locale
  )[0]

  //   console.log(i18nFooter)

  return (
    <i18nContext.Consumer>
      {t => (
        <Box data-datocms-noindex>
          <Box backgroundColor="primary">
            <Container>
              <Flex
                as="ul"
                sx={{
                  listStyle: "none",
                  margin: 0,
                  padding: 0,
                  fontSize: 1,
                  justifyContent: "center",
                  li: {
                    marginRight: 2,
                    marginLeft: 2,
                  },
                }}
              >
                <li>
                  <Link
                    href="https://www.facebook.com/ABCogenerationWorld/"
                    target="blank"
                    rel="noopener nofollow"
                  >
                    <Image src={facebookIcon} alt="" />
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.instagram.com/ab_cogenerationworld"
                    target="blank"
                    rel="noopener nofollow"
                  >
                    <Image src={instagramIcon} alt="" />
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://it.linkedin.com/company/gruppo-ab"
                    target="blank"
                    rel="noopener nofollow"
                  >
                    <Image src={linkedinIcon} alt="" />
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.youtube.com/channel/UCtCSgXMFRZnPORFPH73RuJQ"
                    target="blank"
                    rel="noopener nofollow"
                  >
                    <Image src={youtubeIcon} alt="" />
                  </Link>
                </li>
              </Flex>
            </Container>
          </Box>
          <Box backgroundColor={"dark"}>
            <Container>
              <Flex
                sx={{
                  justifyContent: "center",
                  paddingX: [5, "10rem"],
                  marginY: [3, "5rem"],
                }}
              >
                <Text
                  as="p"
                  sx={{
                    color: "light",
                    fontSize: 6,
                    textAlign: "center",
                    a: {
                      fontWeight: "bold",
                      textDecoration: "none",
                      "&:hover": {
                        color: "light",
                      },
                    },
                  }}
                >
                  {t.subscribe}
                  <Link to={getPagePath("newsletter", locale)}>
                    {t.subscribeLink}
                  </Link>
                </Text>
              </Flex>

              {i18nFooter && (
                <Flex
                  dangerouslySetInnerHTML={{ __html: i18nFooter.website }}
                  sx={{
                    justifyContent: "center",
                    borderTop: "1px solid",
                    borderBottom: "1px solid",
                    borderColor: "primary",
                    paddingTop: 1,
                    paddingBottom: 1,
                    marginTop: 2,
                    marginBottom: 7,
                    a: { "&:hover": { color: "light" } },
                  }}
                />
              )}
              <Grid columns={[1, "1fr 2fr"]}>
                {i18nFooter && (
                  <Box
                    dangerouslySetInnerHTML={{ __html: i18nFooter.links }}
                    sx={{
                      a: { color: "text", "&:hover": { color: "light" } },
                      ul: {
                        listStyle: "none",
                        margin: 0,
                        padding: 0,
                        display: "flex",
                        fontSize: 1,
                        li: {
                          marginRight: 3,
                        },
                      },
                    }}
                  />
                )}
                {i18nFooter && (
                  <Box sx={{ fontSize: 1 }}>{i18nFooter.legal}</Box>
                )}
              </Grid>
            </Container>
          </Box>
        </Box>
      )}
    </i18nContext.Consumer>
  )
}

export default Footer
